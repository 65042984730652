import React, { createContext, useState, useEffect } from 'react';
import { NotificationAlert } from '../components/NotificationAlert';
import PushNotificationService from '../services/PushNotification';
import { useWebsocket } from '../services/WebSocket';
import * as Sentry from "@sentry/browser";

interface INotificationContent {
  type: string;
  id: number;
  description: string;
}
interface INotification {
  id: string;
  read: boolean;
  petshop_id: number;
  created_at: string;
  content: INotificationContent;
}

interface NotificationContextProps {
  count: number;
  notification: INotification[];
  lastNotification: INotificationContent;
  setNotifications: (event: INotification[]) => void;
  setCount: (event: number) => void;
  getData: () => void;
  loading: boolean
  setLoading: (value: boolean) => void
}

export const NotificationContext = createContext({} as NotificationContextProps);

export const NotificationProvider: React.FC = ({ children }) => {
    const [count, setCount] = useState(0);
    const [notification, setNotifications] = useState([] as INotification[]);
    const service = new PushNotificationService();
    const [lastNotification, setLastNotification] = useState({} as INotificationContent)
    const [showNotificationAlert, setShowNotificationAlert] = useState(false)
    const [notificationAlertBody, setNotificationAlertBody] = useState({} as INotificationContent)
    const [loading, setLoading] = useState(false)
    const conection = useWebsocket()
    function handleNotification(notification: INotificationContent) {
      if(notification.type === 'purchase') {
        setShowNotificationAlert(true)
        setNotificationAlertBody(notification)

      }
      setLastNotification(notification)
      getData()
    }
    
    async function getData() {
      setLoading(true)
      await service.getNotifications()
          .then(response => {
              let unread = 0;
              
              response.data.forEach(data => {
                if(!data.read) {
                  unread++; 
                }
              })
              
              setNotifications(response.data)
              setCount(unread)
          })
      setLoading(false)
    }

    async function listenToWebsocket() {
      try {
        const websocket = conection.returnConection()

        if (websocket) {
          websocket?.conection?.on('message', callback => {
          
            handleNotification(callback)
          })
        }
      } catch (error) {
        Sentry.captureException(error);
      }


    }

    useEffect(() => {
      
      listenToWebsocket()
    }, [conection])

    useEffect(() => {
      getData()
    }, [])
    
    return (
        <NotificationContext.Provider
          value={{
            count, 
            notification, 
            lastNotification, 
            setNotifications, 
            setCount, 
            getData,
            loading,
            setLoading
            }}>
            <> 
              {/* <button onClick={() => setShowNotificationAlert(true)}>Not</button> */}
              {children}
              {showNotificationAlert &&
              <NotificationAlert
                notification={{
                  type: notificationAlertBody.type,
                  body: notificationAlertBody.description,
                  id: notificationAlertBody.id}}
                handleCloseAlert={() => setShowNotificationAlert(false)}
              />}
            </>
        </NotificationContext.Provider>
      );
}
