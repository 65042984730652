import HttpService from './config/HttpService';


export default class PushNotificationService {
  private api: HttpService;

  private idPetShop: number;

  constructor() {
    this.api = new HttpService();

    const infoPetshop = window.localStorage.getItem('@iPetis:petshop');
    const idPet =
      infoPetshop && JSON.parse(infoPetshop) && JSON.parse(infoPetshop).id;

    this.idPetShop = idPet;
  }

  registerServiceWorker(body): Promise<any> {
    const url = `/petshop/${this.idPetShop}/service-worker/`;

    return this.api.post(url, body);
  }

  getNotifications() {
    const url = `/web-notification/${this.idPetShop}`;

    return this.api.get(url);
  }

  updateNotifications(body: any[]) {
    const url = `/web-notification/${this.idPetShop}`;
    
    return this.api.put(url, {notifications: body});
  }
}