import styled from 'styled-components';
import { animated } from 'react-spring';

interface ContainerProps {
  type: string;
}

export const Container = styled(animated.div)<ContainerProps>`
  font-family: 'Montserrat';
  width: 296px;
  position: relative;
  padding: 16px 16px 64px 16px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-left: 5px solid
    ${(props) => (props.type === 'success' ? 'var(--success)' : 'var(--error)')};
  display: flex;
  flex-direction: column;
  & + div {
    margin-top: 8px;
  }

  div {
    flex: 1;
    strong {
      font-size: 14px;
      opacity: 0.8;
      color: var(--text-primary);
      font-weight: 600;
    }
  }
  button {
    position: absolute;
    right: 16px;
    bottom: 16px;
    background-color: #7c52a1;
    border: none;
    border-radius: 100px;
    padding: 4px 16px;
    color: #fff;
  }
`;
