import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
  }

:root {
    --white: #ffffff;
    
    --gray-100: #e1e1e6;
    --gray-300: #a8a8b3;
    --gray-500: #5B636F;
    --gray-700: #323238;
    --gray-800: #1f2729;
    --gray-900: #121214;

    --text-primary: #1d211c;
    --text-secondary: #747a85;
    --placeholder: #a5a9b0;
    --disabled: #d6d8db;
    --background: #f9f9f9;

    --error: #ff002e;
    --warning: #ff7a00;
    --success: #6cffa1;
    --background-ligth: #EFEFEF;
    --primary-50: #d9cce3;
    --primary-100: #aa8fc2;
    --primary-300: #7c52a1;
    --primary-500: #653390;
    --primary-700: #472465;
    --primary-800: #381c4f;

    --secondary-50: #fff9c4;
    --secondary-100: #fddc8b;
    --secondary-400: #fcd36c;
    --secondary-700: #fbc02d;
    --secondary-900: #8c4300; 

    --tertiary-50: #A8F5FF;

    --blue: #2a5efc;
    --verde: #36D000;
    --vermelho: #c53030;
    --font-montserat: 'Montserrat';

}

h1,h2,h3,h4,h5,h6 {
  font-family: Montserrat;
}
html,body, #root {
  height: 100vh;
  font-family: Roboto;
}

  body {
    background-color: #EFEFEF;
  }

  button {
    cursor: pointer;
  }

  [disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }
  //Scroolbar styles
  /* width */
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background:#653390; 
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #472465; 
  }

  a:visited {
    color: inherit;
  }
`;
