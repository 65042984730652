import { useState } from 'react'
import ws from '@adonisjs/websocket-client'
import env from '../services/config/Environment'

export function useWebsocket() {
    function returnConection() {
        const petshop = JSON.parse(
            window.localStorage.getItem('@iPetis:petshop') || ''
        )
        
        if (!petshop) throw new Error('Could not found petshop data')
    
        const petshopId = petshop.id
    
        const notification = ws(
            env.currentEnvironment === 'dev'
                ? 'wss://development.ipetis.com.br'
                : 'wss://api.animaupet.com.br'
        )
        
        if (!notification) throw new Error('Could not connect to websocket server')

        notification.connect()

        
        const conection = notification.subscribe(`notification:${petshopId}`)
    
        return { conection }
    }


    return { returnConection }
   
}