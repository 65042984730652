type CheckDevice = {
    isMobile: boolean
    device: string
}

const useCheckDevice = (): CheckDevice => {
    if (!window) {
        throw new Error('window is not defined')
    }

    const userAgent = window.navigator.userAgent
    const isIPhone = userAgent.indexOf('iPhone') != -1
    const isAndroid = userAgent.indexOf('Android') != -1

    return { 
        isMobile: isIPhone || isAndroid,
        device: (isIPhone || isAndroid) && isIPhone ? 'IOS' : 'Android'
    };
}

export default useCheckDevice