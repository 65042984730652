import React, { createContext, useContext } from 'react';
import imageCompression from 'browser-image-compression';
import * as Sentry from "@sentry/browser";
const ImageCompressorContext = createContext({} as any);
interface IOptions { 
    maxSizeMB: number,          // (default: Number.POSITIVE_INFINITY)
    maxWidthOrHeight?: number,   // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
}

export const ImageCompressorProvider: React.FC = ({ children }) => {
    const options: IOptions = {
        maxSizeMB: 2,
    }
    
    async function compress(imageFile: File) {
      
        try {
          const compressedFile = await imageCompression(imageFile, options);
      
          return compressedFile;
        } catch (error) {
          Sentry.captureException(error);
          
        }
    }
    
    return (
        <ImageCompressorContext.Provider
          value={{compress}}>
          {children}
        </ImageCompressorContext.Provider>
      );
}

export function useCompressor() {
    const context = useContext(ImageCompressorContext);
  
    if (!context) {
      throw new Error('useCompressor must be used within an ImageCompressorProvider');
    }
  
    return context;
}
