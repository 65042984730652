import React, { createContext, useState, useContext } from 'react';

interface ProductModel {
    id: number;
    pet_shop_id: number;
    title: string;
    description: string;
    category: string;
    display_in_store: boolean;
    price: number;
    promotion: boolean;
    discount: number;
    price_discount: number;
    available_items: number;
    file_id: any; //tipar esse campo
    product_width: number;
    product_height: number;
    product_length: number;
    product_wight: number;
    visible: boolean;
    created_at: string;
    updated_at: string;
    photos: Photos[];
    evaluation?: any

}

interface Photos {
    id: number;
    products_id: number;
    url_photo: string;
    created_at: string;
    updated_at: string;
}

interface ProductContextModel {
    products: ProductModel[];
    setProducts: any;
  }

const ProductContext = createContext<ProductContextModel>({} as ProductContextModel);

export const ProductProvider: React.FC = ({ children }) => {
    const [products, setProducts] = useState([] as ProductModel[])

    
    return (
        <ProductContext.Provider
          value={{products, setProducts}}>
          {children}
        </ProductContext.Provider>
      );
}

export function useProducts(): ProductContextModel {
    const context = useContext(ProductContext);
  
    if (!context) {
      throw new Error('useProducts must be used within an ProductsProvider');
    }
  
    return context;
  }
