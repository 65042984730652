import React, { useEffect, useRef, useState } from 'react';
import * as Sentry from "@sentry/browser";
import { Container } from './styles';
import IcClose from '../../assets/icons/ic-close.svg';
import { useHistory } from 'react-router-dom';

interface NotificationAlertProps {
    notification: {
        type: string;
        body: string;
        id: number;
    }
    handleCloseAlert: () => void;
}



const animalAudios = {
    dog: 'https://firebasestorage.googleapis.com/v0/b/ipetis-b8f49.appspot.com/o/assets%2Fsounds%2Fdog-barking.mp3?alt=media&token=255373aa-84f5-41ab-ac79-8e9b656f6f09',
    chick: 'https://firebasestorage.googleapis.com/v0/b/ipetis-b8f49.appspot.com/o/assets%2Fsounds%2Fchicks.mp3?alt=media&token=ff3dd0da-0bec-4436-8d8f-2a0b67b0d3d0',
    cat: 'https://firebasestorage.googleapis.com/v0/b/ipetis-b8f49.appspot.com/o/assets%2Fsounds%2Fcat.mp3?alt=media&token=891da30e-88a6-47d6-8fed-a1577fd92d79',
    duck: 'https://firebasestorage.googleapis.com/v0/b/ipetis-b8f49.appspot.com/o/assets%2Fsounds%2Fduck.mp3?alt=media&token=22a61409-cdbe-4b06-a059-fdb5f2b3a45e',
    pig: ''
}

export function NotificationAlert({ notification, handleCloseAlert }: NotificationAlertProps) {
    const audioRef = useRef<HTMLAudioElement>(null)
    const [title, setTitle] = useState('')
    const [audioFile, setAudioFile] = useState('')
    const history = useHistory()
    const [showNotification, setShowNotification] = useState(false)

    function handleClickNotification() {
        history.push(`/pedidos/${notification.id}`)
        audioRef.current?.pause()
        handleCloseAlert()
    }

    function defineAudioFile() {
        const actualAnimal = notification.body.split(' ')[0];
        
        switch (actualAnimal) {
            case '🐣':
                setAudioFile(animalAudios['chick'])
                break;
            case '😺':
                setAudioFile(animalAudios['cat'])
                break;
            case '🦆':
                setAudioFile(animalAudios['duck'])
                break;
            case '🐷':
            case '🐶':
                setAudioFile(animalAudios['dog'])
                break;
        }

        let playPromise = audioRef.current?.play();

        if (playPromise !== undefined) {
            
            playPromise
                .then(_ => {
                // Automatic playback started!
                // Show playing UI.
                setShowNotification(true)
            })
            .catch(error => {
                // Auto-play was prevented
                // Show paused UI.
                setShowNotification(true)
                Sentry.captureException(error);
            });
        }
        
    }

    useEffect(() =>{
        if(notification.type) {
            switch (notification.type) {
                case 'purchase':
                    setTitle(`${notification.body.split('!')[0]}! Novo pedido!`)
                    break;
            
                default:
                    setTitle(notification.body.split('!')[0])
                    break;
            }
        }

        if(audioRef && notification.body) {
            defineAudioFile()
            
        }
            
    }, [notification, audioRef])

    return showNotification ?
    (
        <Container>
            <div className="header">
                <strong>{title}</strong>
                <img src={IcClose} alt="Close" onClick={() => handleCloseAlert()}/>
            </div>
            <div className="content">
                <p>{notification.body}</p>
                <div className="actions">
                    <button type="button" onClick={handleClickNotification}>Ir para o pedido</button>
                    <button type="button" onClick={handleCloseAlert}>Fechar</button>
                </div>
            </div>
            <audio
                autoPlay
                loop
                ref={audioRef}  
                src={audioFile}
            />
        </Container>
    ) : null
}