import styled, { keyframes } from 'styled-components';

const EnterAnimation = keyframes`  
  from { 
    right: -200px;
   }
  to { 
    right: 1rem;
   }
`;

export const Container = styled.div`
    background: #fff;
    padding: .5rem 1rem;
    border-radius: .5rem;
    box-shadow: 2px 2px rgba(0, 0, 0, .2);
    min-width: 350px;
    position: fixed;
    top: 1rem;
    right: 1rem;
    animation: ${EnterAnimation} .5s;
    z-index: 5;
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        font-family: var(--font-montserat);
        font-size: 1.3rem;
        margin: .5rem 0 1rem;
        color: var(--primary-500);
        
        img {
            cursor: pointer;
        }
    }

    .content {
        
        p {
            font-family: Roboto, sans-serif;
            font-size: 1rem;
            line-height: 1.5rem;
            color: var(--gray-500);
            margin-bottom: 1rem; 
        }

        .actions {
            display: flex;
            align-items: center;
            justify-content: space-around;

            button {
                font-family: Roboto, sans-serif;
                border: 0;
                padding: 1rem 2rem;
                width: 100%;
                background: #fff;
                border-bottom: 1px solid var(--primary-500);
                transition: filter .2s;

                & + button {
                    border-left: 1px solid var(--primary-500);
                }

                &:hover {
                    filter: brightness(.9);
                }
            }
        }
    }
`