import styled, { keyframes } from "styled-components";

const animation = keyframes`
  0% {
    width: 0%;
  }
  10% {
    width: 10%;
  }
  20% {
    width: 20%;
  }
  30% {
    width: 30%;
  }
  40% {
    width: 40%;
  }
  50% {
    width: 50%;
  }
  60% {
    width: 60%;
  }
  70% {
    width: 70%;
  }
  80% {
    width: 80%;
  }
  90% {
    width: 90%;
  }
  100% {
    width: 99%;
  }
`

export const Container = styled.div`
  height: 4px;
  width: 100%;
  background: var(--primary-700);
  animation: ${animation} 1000ms ease-in;
  position: fixed;
  top: 0;
`