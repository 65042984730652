import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

if(process.env.REACT_APP_ENV === 'production') {
  Sentry.init({
    dsn: "https://7aa97991a30648bd8441daee7baec2dd@o518950.ingest.sentry.io/5849303",
    integrations: [new Integrations.BrowserTracing({
      tracingOrigins: [
        "localhost",
        "app.animaupet.com.br/",
        "api.animaupet.com.br",
        /^\//
      ],
      // ... other options
    })],
    
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });

  LogRocket.init('dzkzto/dashboard-web');

  // setup react for logrocket
  setupLogRocketReact(LogRocket);

  // setup sentry and logrocket integration
  LogRocket.getSessionURL(sessionURL => {
    Sentry.configureScope(scope => {
      scope.setExtra("sessionURL", sessionURL);
    });
  });
}


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
