import PushNotificationService from './services/PushNotification';
import * as Sentry from "@sentry/browser";

function urlBase64ToUint8Array(base64String: string): Uint8Array {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; i += 1) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

async function registerSW() {
  const service = new PushNotificationService();
  const publicKey =
    'BKC-OypTL4r1dALThia5DzcJ2vfisytMdTE29tI1bltHXKqybtZR-goEG2zWxN0V3R0L3Yj9fPbMg9vtHq4s7z8';

  const swUrl = `${process.env.PUBLIC_URL}/sw.js`;
  const register = await navigator.serviceWorker.register(swUrl, {
    scope: '/',
  });

  // no service worker rgistered
  if (!register) return;

  await register.pushManager
    .subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(publicKey),
    })
    .then(async (subscription) => {
      const registerData = {
        data: subscription,
      };
      try {
        await service.registerServiceWorker(registerData);
      } catch (error) {
        Sentry.captureException(error);
      }
    });
}

export default async function serviceWorker(): Promise<void> {
  
  // registering service worker when its ready
  navigator.serviceWorker.ready.then(registerSW)
  
}
