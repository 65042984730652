import React from 'react';
import {
  Route as ReactDOMRoute,
  RouteProps as ReactRouteProps,
  Redirect,
} from 'react-router-dom';

import { useAuth } from '../hooks/auth';
import useCheckDevice from '../hooks/checkDevice';

interface RouteProps extends ReactRouteProps {
  isPrivate?: boolean;
  component: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  ...rest
}) => {
  const { data } = useAuth();
  const { email } = data;
  const { isMobile } = useCheckDevice()

  function returnCorrectRouteName() {
    if (isPrivate) {
      return '/'
    }

    if (isMobile) {
      return '/mobile'
    }

    return '/dashboard'
  }

  function handleUserRedirect(location: { pathname: string }) {   
    // user not logged in and the route is private
    if (!email && isPrivate) {
      return false
    }

    // user is in mobile device but in the error page
    if (isMobile && location.pathname === '/mobile') {
      return true
    }

    // user is loggedin in mobile device
    if (!!email && isMobile) {
      return false
    }    

    // user is corrected logged int
    return true 
   }

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        return handleUserRedirect(location) === true ? (
          <Component {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: returnCorrectRouteName(),
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default Route;
