import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router } from 'react-router-dom';

import { Provider } from 'react-redux';

import { ProductProvider } from './contexts/ProductContext';
import { ServicePovider } from './contexts/ServicesContext';
import { ImageCompressorProvider } from './contexts/ImageCompressorContext';

import GlobalStyle from './styles/global';

import AppProvider from './hooks';

import Routes from './routes';

import store from './store';

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App: React.FC = () => (
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <Router>
        <AppProvider>
          <ImageCompressorProvider>
            <ProductProvider>
              <ServicePovider>
                <Routes />
              </ServicePovider>
            </ProductProvider>
          </ImageCompressorProvider>
        </AppProvider>

        <GlobalStyle />
        <ReactQueryDevtools />
      </Router>
    </Provider>
  </QueryClientProvider>
);

export default App;
