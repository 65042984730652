const INITIAL_STATE = { messages: [] };

function messagesWarning(state = INITIAL_STATE, action): any {
  switch (action.type) {
    case 'ADD_MESSAGES':
      return { ...state, messages: action.payload };
    default:
      return state;
  }
}

export { messagesWarning };
