import React, { createContext, useState, useContext } from 'react';

interface ServiceModel {
    id: number;
    pet_shop_id: number;
    title: string;
    description: string;
    category: string;
    display_in_store: boolean;
    price: number;
    promotion: boolean;
    discount: number;
    price_discount: number;
    file_id: any; //tipar esse campo
    toy_size: boolean;
    small_size: boolean;
    medium_size: boolean;
    large_size: boolean;
    short_pelage: boolean;
    long_pelage: boolean;
    double_pelage: boolean;
    duration: string;
    simultaneous: number;
    visible: boolean;
    created_at: string;
    updated_at: string;
    photos: Photos[];
    evaluation?: any
}

interface Photos {
    id: number;
    products_id: number;
    url_photo: string;
    created_at: string;
    updated_at: string;
}

interface ServiceContextModel {
    services: ServiceModel[];
    setServices: any;
  }

const ServiceContext = createContext<ServiceContextModel>({} as ServiceContextModel);

export const ServicePovider: React.FC = ({ children }) => {
    const [services, setServices] = useState([] as ServiceModel[])

    
    return (
        <ServiceContext.Provider
          value={{services, setServices}}>
          {children}
        </ServiceContext.Provider>
      );
}

export function useServices(): ServiceContextModel {
    const context = useContext(ServiceContext);
  
    if (!context) {
      throw new Error('useServices must be used within an ProductsProvider');
    }
  
    return context;
  }
