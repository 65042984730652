/**
 * Configuração das rotas do APP para comunicação com a API
 */

export default {
  currentEnvironment: process.env.REACT_APP_ENV,
  baseUrl: {
    local: {
      urlApi: 'https://development.ipetis.com.br/',
      frontUrl: 'http://localhost:3000/',
    },
    dev: {
      urlApi: 'https://development.ipetis.com.br/',
      frontUrl: 'https://devapp.ipetis.com.br/',
    },
    production: {
      urlApi: 'https://api.animaupet.com.br/',
      frontUrl: 'https://app.animaupet.com.br/',
    },
  },
};
