import axios from 'axios';

import environment from './config/Environment';

const Environment: any = environment;

const api = axios.create({
  baseURL: Environment.baseUrl[Environment.currentEnvironment].urlApi,
});

export default api;
