import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firebase-storage';
import * as Sentry from "@sentry/browser";
const firebaseConfig = {
  apiKey: 'AIzaSyCUEc7KqNlTutC-ejgFugXux-oDRwQPk6U',
  authDomain: 'ipetis-b8f49.firebaseapp.com',
  databaseURL: 'https://ipetis-b8f49.firebaseio.com',
  projectId: 'ipetis-b8f49',
  storageBucket: 'ipetis-b8f49.appspot.com',
  messagingSenderId: '778451219994',
  appId: '1:778451219994:web:520cc166629bcd00da43a3',
  measurementId: 'G-BM0QMRN450',
};

app.initializeApp(firebaseConfig);

const auth = app.auth();
const storage = app.storage();

export const Firebase = {
  firebaseAuthentication(): Promise<app.auth.UserCredential> | undefined {
    if (
      process.env.REACT_APP_FIREBASE_CUSTOM_EMAIL &&
      process.env.REACT_APP_FIREBASE_CUSTOM_TOKEN
    ) {
      return auth.signInWithEmailAndPassword(
        process.env.REACT_APP_FIREBASE_CUSTOM_EMAIL,
        process.env.REACT_APP_FIREBASE_CUSTOM_TOKEN,
      );
    }

    return undefined;
  },
  firebaseLogout(): Promise<void> {
    return auth.signOut();
  },

  async storageFile(
    fileName: string,
    file: File,
    type = 'image',
  ): Promise<string> {
    const infoPetshop = window.localStorage.getItem('@iPetis:petshop');

    const jsonParseInfoPetshop = infoPetshop && JSON.parse(infoPetshop);

    const idPetShop = jsonParseInfoPetshop?.id;
    // check for folder in production o dev
    const folder =
      process.env.REACT_APP_ENV === 'production' ? 'petshop' : 'petshop-dev';

    let pathFinal = '';

    if (type === 'image') {
      pathFinal = `${folder}/manager/${idPetShop}/${fileName}`;
    } else if (type === 'docs_juno') {
      pathFinal = `${folder}/docs_juno/${idPetShop}/${fileName}`;
    }

    await storage.ref(pathFinal).put(file);

    return storage.ref(pathFinal).getDownloadURL();
  },

  async deleteFile(
    fileName: string,
    type = 'image',
  ): Promise<'Succsess' | undefined> {
    const infoPetshop = window.localStorage.getItem('@iPetis:petshop');

    const jsonParseInfoPetshop = infoPetshop && JSON.parse(infoPetshop);

    const idPetShop = jsonParseInfoPetshop?.id;
    // check for folder in production o dev
    const folder =
      process.env.REACT_APP_ENV === 'production' ? 'petshop' : 'petshop-dev';

    let pathFinal = '';

    if (type === 'image') {
      pathFinal = `${folder}/manager/${idPetShop}/${fileName}`;
    } else if (type === 'docs_juno') {
      pathFinal = `${folder}/docs_juno/${idPetShop}/${fileName}`;
    }

    try {
      await storage.ref(pathFinal).delete();
      return 'Succsess';
    } catch (error) {
      Sentry.captureException(error);
      return undefined;
    }
  },
};
